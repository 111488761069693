@import "ext/solarized-dark";

.highlight {
}

div.highlight {
  padding: 5px 20px;
}
pre.highlight,
code {
  font-family: "Consolas", Menlo, monospace;
  line-height: 1.4em;
  tab-size: 4;
}
